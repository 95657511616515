.popup-dialog {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-dialog-content {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 16px;
  max-width: 500px;
  width: 100%;
}

.popup-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-dialog-close {
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
}

.popup-dialog-body {
  margin-top: 16px;
}

.popup-dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.popup-dialog-button {
  border: none;
  background-color: #bd1f1f;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.popup-dialog-button:hover {
  color: black;
}