.post-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
}

.post-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 300px;
    margin-right: 16px;
}

.post-small-image {
    border-radius: 8px;
    width: 100%;
    margin-bottom: 10px;
}

.post-small-image:hover {
    cursor: pointer;
}

.post-liking-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.react-paginate {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fit-content;
}

.react-paginate-active {
    background-color: #ededed;
    border-color: transparent;
    border-radius: 4px;
}

.react-paginate-item {
    padding: 10px;
    margin-right: 4px;
    margin-left: 4px;
}

.edit-button {
    width: fit-content;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    background: #bd1f1f;
    color: white;
    border: none;
    border-radius: 4px;
}

.edit-button:hover {
    color: black
}

.edit-button:disabled {
    background: #eee;
}

.post-list-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 1000px) {
    .post-item {
        max-width: 450px;
    }
}

@media (max-width: 650px) {
    .post-item {
        max-width: 600px;
    }
}

.truncate-text {
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow text */
    text-overflow: ellipsis;
    /* Add ellipsis (...) when text overflows */
    max-width: 160px;
    /* Set maximum width for the container */
}

.load-more-button {
    margin-right: auto;
    margin-left: auto;
    width: 200px;
    background: #bd1f1f;
    border-radius: 4px;
    border: none;
    color: white;
    padding: 8px;
}

.load-more-button:hover {
    color: black;
}

.user-thumbnail {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 8px;
    margin-top: 2px;
}