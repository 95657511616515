/* ImageWithOverlay.css */

.image-edit-container {
    position: relative;
    display: inline-block;
}

.overlay-image {
    position: relative;
    height: inherit;
}

.overlay {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    align-items: flex-end;
    border-radius: 4px;
}

.overlay-button {
    padding: 10px;
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.overlay-button:hover {
    color: #bd1f1f;
}

.popup-editor {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.popup-editor-content {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 16px;
    max-width: 1000px;
    max-height: 600px;
    width: 100%;
}

.edit-image-container {
    width: 400px;
    background: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 10/6;
    margin: 10px;
    border-radius: 4px;
}

.aspect-frame {
    border: 1px solid currentColor;
}

.aspect-label {
    width: 200px;
}

.aspect-edit-container {
    display: flex;
    justify-content: space-around;
}

.aspect-container {
    display: flex;
    margin-bottom: 10px;
}

@media (max-width: 850px) {
    .aspect-container {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .aspect-edit-container {
        flex-direction: column;
    }
}