.search-bar-container {
    display: flex;
}

.search-input {
    border: 2px solid #ededed;
    height: 38px;
    border-radius: 16px;
    margin-right: 10px;
    width: 20vw;
    padding: 5px;
}

.button-container {
    display: flex;
    width: 60px;
    height: 38px;
}


.search-icon {
    color: white;
    font-size: 16px;
    background-color: #bd1f1f;
    width: 50%;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    padding-top: 8px;
    padding-left: 6px;
    border-right: 1px solid #ededed;
}

.search-icon:hover {
    color: black
}

.generate-icon {
    color: white;
    font-size: 16px;
    background-color: #bd1f1f;
    width: 50%;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 8px;
    padding-left: 6px;
}

.generate-icon:hover {
    color: black
}

@media (max-width: 800px) {
    .search-input {
        height: 30px;
    }

    .button-container {
        display: flex;
        width: 38px;
        height: 30px;
    }

    .search-icon {
        font-size: 12px;
        padding-top: 6px;
        padding-left: 4px;
    }

    .generate-icon {
        color: white;
        font-size: 12px;
        padding-top: 6px;
        padding-left: 4px;
    }
}