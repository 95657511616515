:root {
    --element-spacing: 1.5rem;
}

.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 10px;
    margin-bottom: 40px;
}

.top-nav-bar {
    color: #333333;
    border-radius: 4px;
    font-weight: bold;
    font-size: 20px;
    background: none;
    border: none;
    margin-right: 20px;
}

.top-nav-bar:hover {
    color: #bd1f1f;
}

.logo {
    width: 40px;
    margin-right: 10px;
}

.create-post-icon {
    color: white;
    font-size: 20px;
    border-radius: 8px;
    background-color: #bd1f1f;
    width: 50px;
    height: 38px;
    padding-top: 5px;
    padding-left: 16px;
}

.create-post-icon:hover {
    color: black
}

.website-name {
    font-size: 26px;
    font-weight: bolder;
    text-transform: uppercase;
    font-family: Arial Narrow;
    letter-spacing: -1px;
    color: #bd1f1f;
}

.logo-letter {
    background-color: #bd1f1f;
    color: white;
    font-size: 26px;
    border-radius: 8px;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    font-weight: bolder;
    padding-left: 11px;
}

@media (max-width: 800px) {
    .logo-letter {
        font-size: 20px;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        padding-left: 8px;
    }

    .large-view {
        display: none;
    }

    .top-nav-bar {
        margin-right: 10px;
    }

    .create-post-icon {
        font-size: 16px;
        width: 40px;
        height: 30px;
        padding-top: 3px;
        padding-left: 12px;
    }
}

@media (min-width: 800px) {
    .small-view {
        display: none;
    }
}