.login-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.popup {
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
}

.close-button {
	position: relative;
	background: none;
	border: none;
	cursor: pointer;
	color: #999;
	left: 95%;
	right: auto;
}

.login-popup {
	border-radius: 4px;
	align-self: center;
	flex-direction: column;
	display: flex;
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
	padding: 20px;
}