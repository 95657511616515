.image-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-container {
    position: relative;
    border-radius: 4px;
    border: 2px dashed #ccc;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.image-container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.upload-prompt {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #999;
}

.upload-prompt span {
    font-size: 16px;
}

input[type='file'] {
    display: none;
}