.landing-page {
    margin-right: auto;
    margin-left: auto;
    max-width: 1000px;
    padding: 20px;
}

.post-catagory {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 30px
}

.footer-container {
    border-top: 1px solid #eee;
    padding-top: 10px;
    color: #aaa;
    font-weight: bold;
}

.type-item {
    margin-right: 16px;
}

.menu-container {
    display: flex;
}

.menu-catogary {
    width: 120px;
    color: black;
    font-weight: bold;
    flex-shrink: 0;
}

.menu-item-container {
    display: flex;
    flex-wrap: wrap;
}

.menu-item {
    width: 100px;
    color: black
}

.menu-item:hover {
    color: #bd1f1f;
}

.type-drop-down {
    position: absolute;
    width: 50vw;
    border: none;
    border-radius: 4px;
    background-color: #f2f2f2;
    min-width: 20vw;
}

.type-selector {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
}

.landing-banner-image:nth-child(even) {
    margin-top: 30px;
    border-radius: 8px;
}

.landing-banner-image:nth-child(odd) {
    margin-bottom: 30px;
    border-radius: 8px;
}

.image-wall-container {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    height: 400px;
    width: 940px;
    margin-bottom: 40px;
    margin-top: 40px;
}

/* App.css */
.image-wall {
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
}

.image-wall video {
    margin-right: 30px;
    border-radius: 8px;
}

.primary {
    animation: primary 10s linear infinite;
}

.secondary {
    animation: secondary 10s linear infinite;
}

@keyframes primary {
    from {
        left: 0%;
    }

    to {
        left: -100%;
    }
}

@keyframes secondary {
    from {
        left: 100%;
    }

    to {
        left: 0%;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 0;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.image-overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 36px;
    text-align: center;
    font-weight: bolder;
    animation: fade-out 30s ease-in-out forwards infinite;
}

.menu-dropdown {
    position: absolute;
    z-index: 100;
    background: white;
    width: 100%;
    opacity: 0.9;
}

.floating-menu {
    position: fixed;
    width: 100%;
    background: white;
    padding-top: 20px;
    z-index: 100;
}