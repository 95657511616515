.settings-container {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.settings-left-nav {
    height: 40px;
    border-radius: 4px;
    padding-right: 20px;
    padding-top: 2px;
}

.settings-left-nav:hover {
    color: #bd1f1f
}

.m-active {
    color: #bd1f1f;
}

.settings-right-pane {
    width: 80%;
    margin-right: 20px;
    border-radius: 8px;
    border: 1px solid;
    height: fit-content;
    padding: 20px;
}

.settings-input {
    border-radius: 4px;
    height: 40px;
    width: 100%;
    margin-left: 10px;
    border: 1px solid black;
    padding: 5px;
}

.settings-label {
    display: inline;
    width: 100px;
}

.settings-item-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.setting-personal-container {
    display: flex;
    flex-direction: column;
}

.setting-personal {
    border-bottom: 1px solid #ededed;
    padding-bottom: 4px;
    margin-bottom: 8px;
    align-items: center;
}

.settings-button-container {
    margin-left: 100px;
}

.settings-button {
    width: 100px;
    font-size: large;
    background: #bd1f1f;
    border-radius: 4px;
    border: none;
    color: white;
    padding: 10px
}

.settings-button:hover {
    color: black;
}

.settings-button:disabled {
    background: #5d5d5d;
    color: white;
}

.setting-nav {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

@media (max-width: 600px) {
    .settings-container {
        display: flex;
        flex-direction: column;
    }

    .settings-right-pane {
        width: 100%;
    }

    .settings-photo-line {
        margin-left: 20px;
    }
}

.persona-image {
    border-radius: 50%;
}

.persona-selector {
    width: 200px;
    border-radius: 50%;
    height: 200px;
}

.follow-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.follow-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.earnings-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 10px;
}

.earnings-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    max-width: 300px;
    background-color: #eee;
    border-radius: 4px;
    padding: 10px;
    height: 100px;
}

.row-container {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.persona-photo {
    width: 200px;
    height: 200px;
    margin-right: 20px;
    border-radius: 50%;
}

.persona-container {
    display: flex;
    margin-bottom: 50px;
}