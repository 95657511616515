.comment-input {
    word-break: break-word;
    border-bottom: 1px solid;
    width: 100%;
    padding: 10px;
    margin: 10px;
}

.comment-input:focus-visible {
    border: none;
}

.comment-button {
    margin: 8px;
    width: 60px;
    background: #bd1f1f;
    border-radius: 4px;
    border: none;
    color: white;
    padding: 8px;
}

.comment-button:hover {
    color: black;
}

.comment-button-container {
    display: flex;
    justify-content: flex-end;
}

.comment-container {
    display: flex;
    justify-content: space-between;
}

.comment-item {
    margin-bottom: 10px;
}

.hide {
    opacity: 0;
}

.comment-item:hover .hide {
    opacity: 1;
}

[contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: gray;
    background-color: transparent;
}

.dropdown {
    position: relative;
    display: inline-block;
    align-self: center;
}

.dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-top: 20px;
    transform: translate(-100%, -100%);
}

.dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content div:hover {
    background-color: #ddd;
}

.checbox-group {
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.checbox-group .input {
    margin-right: 10px;
}