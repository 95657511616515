.editor-container {
    max-width: 824px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.title-input {
    height: 40px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid black;
    padding: 5px;
    width: 100%;
    margin-right: 10px;
}

.type-select {
    height: 40px;
    border-radius: 4px;
    border: 1px solid black;
    padding: 5px;
}

.description {
    height: 150px;
    border-radius: 4px;
    margin-bottom: 60px;
}

.description .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid black;
}

.description .ql-snow.ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid black;
}

.description .ql-container.ql-snow {
    border: 1px solid black;
}

.step {
    display: flex;
    margin-bottom: 20px;
}

.step-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.step-content {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid black;
}

.step-image {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 4px;
}

.step-image-container {
    width: 200px;
    height: 200px;
    flex-shrink: 0;
}

.banner-image-container {
    width: 20%;
    height: 100px;
    margin-bottom: 20px;
}

.banner-image {
    width: 100%;
    height: 100%;
}

.product-input {
    height: 30px;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid black;
    padding: 5px;
    width: 50%;
}

.ingredient-input {
    height: 30px;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid black;
    padding: 5px;
    width: 30%;
}

.product-buttons {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.product {
    margin-bottom: 20px;
    background: #eee;
    padding: 10px;
    border-radius: 8px;
}

.weight-input {
    height: 30px;
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid black;
    padding: 5px;
    width: 15%;
}

.publish-button {
    width: fit-content;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    background: #bd1f1f;
    color: white;
    border: none;
    border-radius: 4px;
}

.publish-button:hover {
    color: black
}

.publish-button:disabled {
    background: #eee;
}

.image-editor-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    margin-bottom: 10px;
}

.image-editor {
    border-radius: 4px;
    position: relative;
    display: flex;
    background: gray;
    justify-content: center;
    align-items: center;
}

.tag-input {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 4px;
    padding: 5px;
}

.provider-selector {
    height: 30px;
}

@media (max-width: 850px) {
    .banner-image-container {
        height: 420px;
    }
}

@media (max-width: 800px) {
    .banner-image-container {
        height: 390px;
    }
}

@media (max-width: 750px) {
    .banner-image-container {
        height: 360px;
    }
}

@media (max-width: 700px) {
    .banner-image-container {
        height: 330px;
    }
}

@media (max-width: 650px) {
    .banner-image-container {
        height: 300px;
    }
}

@media (max-width: 600px) {
    .banner-image-container {
        height: 280px;
    }
}

@media (max-width: 550px) {
    .banner-image-container {
        height: 260px;
    }
}

@media (max-width: 450px) {
    .banner-image-container {
        height: 220px;
    }
}