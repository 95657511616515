.detail-page {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.banner-detail {
    width: 100%;
    aspect-ratio: 10/6;
    background: gray;
    border-radius: 4px;
}

.image-detail {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-bottom: 20px;
}

.step-image-detail {
    width: 150px;
    border-radius: 8px;
    margin-right: 20px;
}

.title-detail {
    font-size: 30px;
    font-weight: bold;
    width: 70%;
}

.title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.follow-detail-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.post-data-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.like-icon {
    color: #bd1f1f;
    transition: transform 0.3s ease;
    margin-left: 10px;
    font-size: large;
}

.like-icon:hover {
    transform: scale(1.2);
}

.table {
    border-collapse: collapse;
    width: 100%;
}

.table tbody {
    background-color: grey !important;
    border-radius: 4px;
}

.table-container {
    background-color: #eee;
    border-radius: 4px;
    width: 100%;
}

.table-container table {
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;

}

.table-container td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    width: 50%;
}

.table-container td:first-child {
    border-right: 1px solid #ddd;
}

.table-container tr:last-child td {
    border-bottom: none;
}

.title-catogary {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 22px;
    font-weight: bold;
}

.shop-button {
    border-radius: 4px;
    background-color: #bd1f1f;
    border: none;
    margin-top: 20px;
    height: 38px;
    padding: 5px;
    color: white;
}

.shop-button:hover {
    color: black;
}

@media (max-width: 800px) {
    .title-detail {
        font-size: 20px;
    }

    .follow-detail-container {
        font-size: 12px;
    }

    .post-data-container {
        font-size: 12px;
    }

    .save-like-container {
        font-size: 12px;
    }

    .title-catogary {
        font-size: 18px;
    }

    .table-container {
        font-size: 12px;
    }
}

.post-detail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.post-detail-popup {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    max-height: 800px;
    width: 1000px;
    overflow: auto;
}

.follow-button {
    border: none;
    background: #bd1f1f;
    color: white;
    padding: 5px;
    border-radius: 4px;
}

.follow-button:hover {
    color: black;
}