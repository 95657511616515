.mentions {
    margin: 1em 0;
    width: 100%;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
}

.mentions--singleLine .mentions__highlighter {
    padding: 10px;
    border: 2px inset transparent;
    visibility: hidden;
}

.mentions--singleLine .mentions__input {
    padding: 1px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    height: 40px;
    padding: 10px;
}

.mentions--multiLine .mentions__control {
    font-family: monospace;
    font-size: 14pt;
}

.mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    padding: 9px;
    min-height: 63px;
}

.mentions--multiLine .mentions__input {
    border: 1px solid silver;
    padding: 9px;
    outline: 0;
}

.mentions__suggestions__list {
    background-color: gray;
    font-size: 10pt;
    border-radius: 4px;
    border: none;
}

.mentions__suggestions__item {
    padding: 5px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.mentions__suggestions__item--focused {
    background-color: #bdbdbd;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: rgb(69, 43, 59);
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
        -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}