.edit-publish-button {
    width: fit-content;
    height: 30px;
    padding: 5px;
    margin-right: 10px;
    background: #bd1f1f;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
}

.edit-publish-button:hover {
    color: black
}

.instruction {
    font-size: small;
    color: grey;
}