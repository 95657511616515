/* ImageOverlay.css */

.image-overlay-container {
    position: relative;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.image-overlay {
    position: relative;
    border-radius: 4px;
}

img {
    border-radius: 4px;
}

.prev-image,
.next-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 24px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    border-radius: 50%;
    z-index: 100;
}

.prev-image {
    left: 10px;
}

.next-image {
    right: 10px;
}

.dot-container {
    position: absolute;
    top: 90%;
    left: 50%;
    display: flex;
    justify-content: center;
    z-index: 100;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #aaa;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #eee;
}