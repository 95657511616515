/* ImageUpload.css */

.image-upload-container {
    position: relative;
}

.upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #bd1f1f;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.upload-button:hover {
    background-color: #b01212;
}

.upload-button input {
    display: none;
}

.preview-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}