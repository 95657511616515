.login-form {
	background: #f2f2f2;
	border: 1px solid #bbbbbb;
	border-radius: 4px;
	align-self: center;
	flex-direction: column;
	display: flex;
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
	margin-top: 100px;
	padding: 20px;
}

.login-input {
	border-width: 1px;
	border-radius: 4px;
	border-color: #555555;
	align-self: center;
	width: 100%;
	height: 40px;
	margin: 16px;
}

.validation-input {
	border-width: 1px;
	border-radius: 4px;
	border-color: #555555;
	align-self: center;
	height: 40px;
}

.login-input:hover {
	border-color: #cccccc;
}

.login-button {
	background: #bd1f1f;
	color: white;
	border-radius: 4px;
	border-width: 0px;
	width: 100%;
	height: 40px;
	margin: 16px;
	align-self: center;
	font-size: 20px;
}

.login-button:hover {
	background: #bd1f1f;
	color: #333333;
}

.login-text {
	font-size: 28px;
}

.reset-link {
	margin-bottom: 16px;
	font-size: 16px;
}

.error-message {
	margin-bottom: 16px;
	font-size: 16px;
	color: #bd1f1f;
}

.validation-button {
	background: #bd1f1f;
	color: white;
	border-radius: 4px;
	border-width: 0px;
	height: 40px;
	align-self: center;
	font-size: 16px;
	padding: 10px;
}

.validation-container {
	display: flex;
	justify-content: space-between;
}